import clsx from 'clsx';
import { graphql } from 'gatsby';

import { valueToLowerCase } from '../utils/helpers';
import InputWrapper from './InputWrapper';

const Html = ({ fieldData, name, wrapClassName, ...wrapProps }) => {
  const { content, cssClass, type } = fieldData;

  return (
    <InputWrapper
      {...wrapProps}
      inputData={fieldData}
      labelFor={name}
      wrapClassName={clsx(
        wrapClassName,
        'gfield_html',
        'gfield_html_formatted',
        'gfield_no_follows_desc',
        'gravityform__' + valueToLowerCase(type) + '__wrap',
        cssClass,
      )}
    >
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </InputWrapper>
  );
};

export default Html;

export const HtmlField = graphql`
  fragment HtmlField on WpHtmlField {
    conditionalLogic {
      ...ConditionalLogic
    }
    content
    cssClass
    hasMargins
    label
  }
`;
