import clsx from 'clsx';
import { graphql } from 'gatsby';
import { useFormContext } from 'react-hook-form';

import { valueToLowerCase } from '../utils/helpers';
import strings from '../utils/strings';
import InputWrapper from './InputWrapper';

const Textarea = ({ defaultValue, fieldData, name, wrapClassName, wrapId }) => {
  const {
    cssClass,
    inputMaskValue,
    isRequired,
    maxLength,
    placeholder,
    size,
    type: typeUpper,
  } = fieldData;

  const type = valueToLowerCase(typeUpper);

  const regex = inputMaskValue ? new RegExp(inputMaskValue) : false;

  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <InputWrapper
      errors={errors?.[name] || {}}
      inputData={fieldData}
      labelFor={name}
      wrapClassName={wrapClassName}
      wrapId={wrapId}
    >
      <textarea
        aria-invalid={Boolean(errors?.[name])}
        aria-required={isRequired}
        className={clsx(
          'gravityform__field__input',
          `gravityform__field__input__${type}`,
          cssClass,
          valueToLowerCase(size),
          'textarea',
        )}
        defaultValue={defaultValue}
        id={name}
        maxLength={maxLength > 0 ? maxLength : undefined}
        name={name}
        placeholder={placeholder}
        {...register(name, {
          required: isRequired && strings.errors.required,
          maxlength: {
            value: maxLength > 0 && maxLength,
            message:
              maxLength > 0 &&
              `${strings.errors.maxChar.front}  ${maxLength} ${strings.errors.maxChar.back}`,
          },
          pattern: {
            value: regex,
            message: regex && strings.errors.pattern,
          },
        })}
        type={type}
      />
    </InputWrapper>
  );
};

export default Textarea;

export const TextAreaField = graphql`
  fragment TextAreaField on WpTextAreaField {
    adminLabel
    canPrepopulate
    conditionalLogic {
      actionType
      rules {
        fieldId
        operator
        value
      }
    }
    cssClass
    defaultValue
    description
    descriptionPlacement
    errorMessage
    inputName
    isRequired
    label
    maxLength
    shouldAllowDuplicates
    placeholder
    size
    hasRichTextEditor
    value
  }
`;
