import { useLocation } from '@reach/router';
import clsx from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';
import { useEffect, useState } from 'react';

import { useMediaQuery } from '../hooks/useMediaQuery';
import Link from './link';

export const Header = () => {
  const {
    wpMenu: {
      menuItems: { nodes: menuItems },
    },
    wp: {
      siteOptions: {
        fields: { helloBarContent },
      },
    },
  } = useStaticQuery(graphql`
    query {
      wpMenu(name: { eq: "Main" }) {
        id
        menuItems {
          nodes {
            id
            uri
            label
          }
        }
      },
      wp {
        siteOptions {
          fields: siteFields {
            __typename
            helloBarContent
          }
        }
      }
    }
  `);

  const { pathname } = useLocation();
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [isExpanded, toggleExpansion] = useState(false);
  const isMobile = useMediaQuery('(max-width: 769px)');

  const isHome = pathname === '/';
  const domain = new RegExp(
    process.env.FRONTEND_URL + '|https://readysetrocket.com',
    'gi',
  );

  // const handleScroll = debounce(() => {
  const handleScroll = () => {
    // find current scroll position
    const currentScrollPos = window.pageYOffset;

    // set state based on location info
    setVisible(
      (prevScrollPos > currentScrollPos &&
        prevScrollPos - currentScrollPos > 70) ||
        currentScrollPos < 10 ||
        prevScrollPos > currentScrollPos,
    );

    // set state to new scroll position
    setPrevScrollPos(currentScrollPos);
  };
  // }, 100);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos, visible, handleScroll]);

  return (
    <header
      className={clsx(
        'header fixed flex-wrap inset-x-0 top-0 flex bg-transparent p-[10px] text-white md:p-[20px]',
        isHome && 'is-home',
        isExpanded && 'is-active',
      )}
      style={{ top: visible ? '0' : '-163px' }}
    >
      {helloBarContent && (
        <div className="hello-bar" id="hello-bar" dangerouslySetInnerHTML={{ __html: helloBarContent }}  />
      )}
      <div className="brand flex shrink basis-[calc(65px+18.26388889vw)] justify-start">
        <Link
          onClick={() => toggleExpansion(false)}
          to={`/`}
          className="no-underline"
        >
          <Logo />
        </Link>
        <small>A Sia Partners Company</small>
      </div>

      <nav
        className={clsx(
          'header-menu relative flex-1 items-start justify-between gap-[10px] md:flex',
          isExpanded && 'is-active',
        )}
      >
        {isMobile ? (
          <div className="mobile-menu">
            {menuItems.map(({ uri, label }) => {
              const link = uri.replace(domain, '');
              return (
                <Link
                  onClick={() => toggleExpansion(!isExpanded)}
                  key={label}
                  to={link}
                  className="header-menu-item"
                  getProps={({ isPartiallyCurrent }) =>
                    isPartiallyCurrent
                      ? { className: 'header-menu-item active' }
                      : null
                  }
                >
                  {label}
                </Link>
              );
            })}
          </div>
        ) : (
          menuItems.map(({ uri, label }) => {
            const link = uri.replace(domain, '');
            return (
              <Link
                key={label}
                to={link}
                className="header-menu-item"
                getProps={({ isPartiallyCurrent }) =>
                  isPartiallyCurrent
                    ? { className: 'header-menu-item active' }
                    : null
                }
              >
                {label}
              </Link>
            );
          })
        )}
      </nav>

      <button
        onClick={() => toggleExpansion(!isExpanded)}
        className={clsx(
          'menu-btn absolute right-[8px] md:right-[18px] top-[8px] flex items-center p-[4px] text-white collapse:hidden',
          isExpanded && 'is-active',
        )}
      >
        <MenuIcon />
      </button>
    </header>
  );
};

const MenuIcon = () => (
  <svg
    className="menu-icon"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Menu</title>
    <style>{`.menu-icon rect { width: 4px; height: 4px; fill: currentColor;}`}</style>
    <rect x="16" />
    <rect x="7.99219" className="hide" />
    <rect />
    <rect x="16" y="8" className="hide" />
    <rect x="7.99219" y="8" />
    <rect y="8" className="hide" />
    <rect x="16" y="16" />
    <rect x="7.99219" y="16" className="hide" />
    <rect y="16" />
  </svg>
);

const Logo = () => (
  <svg
    width="65"
    height="52"
    viewBox="0 0 65 52"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Ready Set Rocket</title>
    <path d="M8.30487 7.72395C9.77861 7.21986 10.9846 5.89687 10.9846 3.94599C10.9828 1.3849 9.32178 0 6.6211 0H1.20047C1.0552 0 0.992188 0.0619048 0.992188 0.210476V14.4822C0.992188 14.629 1.0552 14.6927 1.20047 14.6927H2.9455C3.09078 14.6927 3.15379 14.629 3.15379 14.4822V7.89197H5.87548L9.07325 14.4822C9.15551 14.629 9.21852 14.6927 9.36379 14.6927H11.2751C11.4204 14.6927 11.5026 14.629 11.4414 14.4822L8.30487 7.72395ZM3.15379 5.79252V2.09769H6.70511C8.07733 2.09769 8.8247 2.7698 8.8247 3.94422C8.8247 5.11864 8.07733 5.79075 6.70511 5.79075H3.15379V5.79252Z" />
    <path d="M17.2999 14.9007C19.71 14.9007 21.4113 13.8731 22.2847 11.5225C22.3249 11.3969 22.3249 11.3544 22.3249 11.312C22.3249 11.2271 22.2847 11.1652 22.1796 11.1652H20.4136C20.2683 11.1652 20.2053 11.1864 20.1231 11.3544C19.6032 12.4669 18.7106 12.9286 17.2981 12.9286C15.5531 12.9286 14.3909 11.8585 14.1616 10.0951H22.3232C22.4684 10.0951 22.5315 10.0314 22.5315 9.88463V9.48667C22.5315 6.48518 20.5799 3.94531 17.2771 3.94531C14.2229 3.94531 12 6.25524 12 9.423C12.0018 12.5925 14.1844 14.9007 17.2999 14.9007ZM17.2789 5.91742C18.7526 5.91742 19.9585 6.69388 20.3103 8.20613H14.2456C14.6604 6.61075 15.9906 5.91742 17.2789 5.91742Z" />
    <path d="M28.2843 7.93198C25.9582 7.93198 24.0469 9.14885 24.0469 11.4782C24.0469 13.7033 25.8549 14.8989 27.8065 14.8989C29.4062 14.8989 30.3199 14.4161 31.0463 13.7033V14.4797C31.0463 14.6265 31.1075 14.6902 31.2545 14.6902H33.3304C33.4756 14.6902 33.5597 14.6265 33.4756 14.4797C33.1851 13.9544 33.0818 13.5565 33.0818 12.5501V7.84885C33.0818 5.41334 31.4191 3.94531 28.8234 3.94531C26.373 3.94531 24.9185 5.33021 24.5037 7.03171C24.4827 7.11484 24.4827 7.19973 24.4827 7.24041C24.4827 7.32531 24.544 7.36599 24.628 7.36599H26.373C26.4973 7.36599 26.6005 7.34477 26.6635 7.17674C27.0171 6.29592 27.7435 5.83429 28.8234 5.83429C30.2761 5.83429 31.0463 6.69388 31.0463 7.99565V8.37416C30.1326 8.10001 29.4062 7.93198 28.2843 7.93198ZM28.097 12.9286C26.8508 12.9286 26.2067 12.3414 26.2067 11.3969C26.2067 10.3268 27.1834 9.82273 28.3876 9.82273C29.4675 9.82273 30.0906 9.96953 31.0463 10.2631C31.0463 11.9841 29.6968 12.9286 28.097 12.9286Z" />
    <path d="M39.5927 14.9014C40.9422 14.9014 42.0011 14.4822 42.7905 13.7057V14.4822C42.7905 14.629 42.8535 14.6927 42.9987 14.6927H44.6195C44.7648 14.6927 44.8278 14.629 44.8278 14.4822V0.210476C44.8278 0.0636735 44.7648 0 44.6195 0H42.9987C42.8535 0 42.7905 0.0619048 42.7905 0.210476V5.16286C41.8978 4.42884 40.9229 3.94422 39.5927 3.94422C36.7887 3.94422 34.8984 6.08612 34.8984 9.42191C34.8984 12.7612 36.787 14.9014 39.5927 14.9014ZM40.0075 5.9181C41.7525 5.9181 42.9567 7.36667 42.9567 9.42367C42.9567 11.4807 41.7508 12.9293 40.0075 12.9293C38.1995 12.9293 37.0583 11.4807 37.0583 9.42367C37.0583 7.36667 38.1995 5.9181 40.0075 5.9181Z" />
    <path d="M56.0584 4.16016H54.2101C54.0228 4.16016 53.9826 4.22383 53.9406 4.37063L51.6145 11.6542L48.8315 4.37063C48.7895 4.22383 48.7282 4.16016 48.562 4.16016H46.6507C46.5474 4.16016 46.4844 4.22383 46.4844 4.32818C46.4844 4.39186 46.5054 4.43254 46.5264 4.49621L50.6798 14.6327C50.181 16.4597 49.5999 17.0894 48.562 17.0894C47.7096 17.0894 47.3158 16.6278 46.9832 16.6278C46.9009 16.6278 46.8169 16.6915 46.8169 16.8383V18.3912C46.8169 18.5168 46.8379 18.6211 47.0252 18.7272C47.482 18.9377 48.0001 19.0633 48.7895 19.0633C50.2422 19.0633 51.5112 18.37 52.4038 15.7257L56.2054 4.45553C56.2265 4.39363 56.2264 4.35118 56.2264 4.30873C56.2247 4.20261 56.1599 4.16016 56.0584 4.16016Z" />
    <path d="M5.48189 33.3776C8.76367 33.3776 10.9655 31.8035 10.9655 28.802C10.9655 26.4726 8.67965 25.4432 6.18725 24.7517C4.11142 24.1857 2.53266 23.6391 2.53266 22.2118C2.53266 20.9737 3.73685 20.3653 5.37862 20.3653C6.95738 20.3653 8.18257 21.1205 8.32785 22.4859C8.34885 22.6327 8.39086 22.6964 8.53613 22.6964H10.2794C10.4247 22.6964 10.4877 22.6327 10.4877 22.4859C10.4474 20.2821 8.59914 18.2676 5.37862 18.2676C2.71994 18.2676 0.37281 19.6525 0.37281 22.3179C0.37281 24.7747 2.28412 25.8447 5.23335 26.5787C7.24792 27.0828 8.80568 27.7956 8.80568 28.9293C8.80568 30.5459 7.66274 31.28 5.48189 31.28C3.48831 31.28 2.26312 30.2718 2.15985 28.5933C2.15985 28.4465 2.09684 28.3828 1.95157 28.3828H0.208284C0.0630102 28.3828 0 28.4465 0 28.5933C0 31.28 2.38914 33.3776 5.48189 33.3776Z" />
    <path d="M22.8735 28.3592V27.9613C22.8735 24.9598 20.9201 22.4199 17.6191 22.4199C14.5649 22.4199 12.3438 24.7299 12.3438 27.8976C12.3438 31.0671 14.5246 33.3753 17.6401 33.3753C20.0502 33.3753 21.7533 32.3477 22.6249 29.9971C22.6669 29.8715 22.6669 29.829 22.6669 29.7866C22.6669 29.7017 22.6249 29.6398 22.5216 29.6398H20.7574C20.6121 29.6398 20.5491 29.661 20.4668 29.829C19.947 30.9416 19.0543 31.4032 17.6419 31.4032C15.8968 31.4032 14.7346 30.3331 14.5053 28.5697H22.6669C22.8122 28.5697 22.8735 28.506 22.8735 28.3592ZM14.5859 26.6807C15.0007 25.0854 16.3309 24.392 17.6191 24.392C19.0928 24.392 20.2988 25.1685 20.6523 26.6807H14.5859Z" />
    <path d="M25.6918 30.0406C25.6918 32.1807 27.0203 33.3781 29.0979 33.3781C29.8452 33.3781 30.6766 33.2525 31.2787 33.0208C31.445 32.9572 31.445 32.8528 31.445 32.6636V31.2362C31.445 31.1319 31.3837 31.047 31.2787 31.047C31.2367 31.047 31.2157 31.047 31.1545 31.0682C30.4281 31.3406 29.9083 31.4042 29.2852 31.4042C28.2052 31.4042 27.7274 30.7958 27.7274 29.6833V24.6036H31.0302C31.1755 24.6036 31.2385 24.5399 31.2385 24.3931V22.8402C31.2385 22.6934 31.1772 22.6297 31.0302 22.6297H27.7274V19.607C27.7274 19.4602 27.6661 19.3965 27.5209 19.3965H25.9001C25.7548 19.3965 25.6918 19.4602 25.6918 19.607V22.6297H23.8645C23.7193 22.6297 23.6562 22.6934 23.6562 22.8402V24.3931C23.6562 24.5399 23.7193 24.6036 23.8645 24.6036H25.6918V30.0406Z" />
    <path d="M8.30487 44.6732C9.77861 44.1691 10.9846 42.8461 10.9846 40.8952C10.9846 38.3341 9.32353 36.9492 6.62285 36.9492H1.20047C1.0552 36.9492 0.992188 37.0111 0.992188 37.1597V51.4314C0.992188 51.5782 1.0552 51.6419 1.20047 51.6419H2.9455C3.09078 51.6419 3.15379 51.5782 3.15379 51.4314V44.8412H5.87548L9.07325 51.4314C9.15551 51.5782 9.21852 51.6419 9.36379 51.6419H11.2751C11.4204 51.6419 11.5026 51.5782 11.4414 51.4314L8.30487 44.6732ZM3.15379 42.7417V39.0487H6.70511C8.07733 39.0487 8.8247 39.7208 8.8247 40.8952C8.8247 42.0696 8.07733 42.7417 6.70511 42.7417H3.15379Z" />
    <path d="M17.4675 40.8945C14.373 40.8945 12.0469 43.1196 12.0469 46.3722C12.0469 49.6249 14.373 51.8499 17.4675 51.8499C20.562 51.8499 22.8881 49.6249 22.8881 46.3722C22.8881 43.1196 20.562 40.8945 17.4675 40.8945ZM17.4675 49.8778C15.5562 49.8778 14.2067 48.4717 14.2067 46.3722C14.2067 44.2745 15.5562 42.8666 17.4675 42.8666C19.3771 42.8666 20.7283 44.2728 20.7283 46.3722C20.7283 48.4717 19.3771 49.8778 17.4675 49.8778Z" />
    <path d="M29.4689 42.8725C30.6521 42.8725 31.7128 43.4385 32.1276 44.6783C32.1889 44.8464 32.2729 44.8676 32.4181 44.8676H34.1019C34.1842 44.8676 34.2472 44.8252 34.2472 44.742C34.2472 44.6996 34.2472 44.6164 34.2262 44.5315C33.8306 42.4745 32.1276 40.9004 29.4707 40.9004C26.3132 40.9004 24.1953 43.1679 24.1953 46.3781C24.1953 49.5883 26.3149 51.8558 29.4707 51.8558C32.1293 51.8558 33.8324 50.2816 34.2262 48.2246C34.2472 48.1415 34.2472 48.0566 34.2472 48.0159C34.2472 47.931 34.1859 47.8903 34.1019 47.8903H32.4181C32.2729 47.8903 32.1889 47.9115 32.1276 48.0796C31.7128 49.3177 30.6539 49.8854 29.4689 49.8854C27.5786 49.8854 26.3534 48.6473 26.3534 46.3798C26.3534 44.1106 27.5786 42.8725 29.4689 42.8725Z" />
    <path d="M41.0673 44.9011L44.7429 41.4168C44.8059 41.3531 44.8269 41.2912 44.8269 41.2488C44.8269 41.1656 44.7849 41.102 44.6606 41.102H42.5218C42.3765 41.102 42.2925 41.1869 42.1892 41.2912L38.2231 45.0692V37.1577C38.2231 37.0109 38.1618 36.9473 38.0148 36.9473H36.3958C36.2505 36.9473 36.1875 37.0092 36.1875 37.1577V51.4294C36.1875 51.5762 36.2488 51.6399 36.3958 51.6399H38.0148C38.1601 51.6399 38.2231 51.5762 38.2231 51.4294V47.5896L39.5305 46.3515L42.8946 51.4507C42.9979 51.6187 43.0819 51.6399 43.2271 51.6399H45.0964C45.2417 51.6399 45.2837 51.5762 45.2837 51.4719C45.2837 51.4294 45.2837 51.41 45.2417 51.3251L41.0673 44.9011Z" />
    <path d="M50.7363 40.8945C47.682 40.8945 45.4609 43.2045 45.4609 46.3722C45.4609 49.5417 47.6418 51.8499 50.7573 51.8499C53.1674 51.8499 54.8705 50.8223 55.7421 48.4717C55.7841 48.3461 55.7841 48.3036 55.7841 48.2612C55.7841 48.1763 55.7421 48.1144 55.6388 48.1144H53.8745C53.7293 48.1144 53.6663 48.1356 53.584 48.3036C53.0642 49.4162 52.1715 49.8778 50.759 49.8778C49.014 49.8778 47.8518 48.8077 47.6225 47.0443H55.7841C55.9294 47.0443 55.9924 46.9807 55.9924 46.8339V46.4359C55.9906 43.4344 54.0373 40.8945 50.7363 40.8945ZM47.7031 45.1553C48.1179 43.56 49.4481 42.8666 50.7363 42.8666C52.21 42.8666 53.416 43.6431 53.7695 45.1553H47.7031Z" />
    <path d="M64.4037 49.5216C64.3617 49.5216 64.3407 49.5216 64.2795 49.5428C63.5531 49.8152 63.0333 49.8788 62.4102 49.8788C61.3302 49.8788 60.8524 49.2704 60.8524 48.1579V43.0782H64.1552C64.3005 43.0782 64.3635 43.0145 64.3635 42.8677V41.3148C64.3635 41.168 64.3022 41.1043 64.1552 41.1043H60.8524V38.0816C60.8524 37.9348 60.7911 37.8711 60.6459 37.8711H59.0251C58.8798 37.8711 58.8168 37.9348 58.8168 38.0816V41.1043H56.9895C56.8443 41.1043 56.7812 41.168 56.7812 41.3148V42.8677C56.7812 43.0145 56.8443 43.0782 56.9895 43.0782H58.8168V48.5152C58.8168 50.6553 60.1453 51.8527 62.2229 51.8527C62.9703 51.8527 63.8016 51.7271 64.4037 51.4954C64.57 51.4318 64.57 51.3274 64.57 51.1382V49.7108C64.57 49.6065 64.507 49.5216 64.4037 49.5216Z" />
  </svg>
);
