import { graphql, useStaticQuery } from 'gatsby';

import Link from './link';
import NewsletterForm from './newsletterForm';

export const Footer = () => {
  const {
    wp: {
      siteOptions: {
        fields: { address, phone },
      },
    },
  } = useStaticQuery(graphql`
    query {
      wp {
        siteOptions {
          fields: siteFields {
            address
            phone
          }
        }
      }
    }
  `);

  return (
    <footer className="footer animate">
      <div>
        <div className="mx-auto items-end justify-between gap-[10px] px-[20px] pb-[20px] text-[14px]">
          <Link to="/" className="logo no-underline">
            <Logo />
            <small className="opacity-[0.48] mt-[8px]">A Sia Partners Company</small>
          </Link>

          <div className="bcorp-logo">
            <BcorpLogo />
          </div>

          <div className="contact-group">
            <div className="social flex gap-[20px]">
              <a
                href="https://www.instagram.com/readysetrocket/"
                target="_blank"
              >
                <IG />
              </a>
              <a
                href="https://www.linkedin.com/company/ready-set-rocket/"
                target="_blank"
              >
                <IN />
              </a>
            </div>

            <a className="email" href="mailto:info@readysetrocket.com">
              info@readysetrocket.com
            </a>
          </div>

          <NewsletterForm />

          <div className="address whitespace-nowrap text-right">

            <address className="whitespace-pre-line not-italic">
              {address}
            </address>
            
            <a
              href={`tel:+1${phone.replace(' ', '')}`}
              className="no-underline"
            >
              {phone}
            </a>

            <div className="privacy-policy">
              <a href="/privacy-policy" target="_blank">Privacy Policy</a>
            </div>

          </div>


        </div>
      </div>
    </footer>
  );
};

const Logo = () => (
  <svg
    width="127"
    height="103"
    viewBox="0 0 127 103"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M16.3367 15.6339C19.2355 14.6424 21.6075 12.0402 21.6075 8.20292C21.6041 3.16542 18.3369 0.441406 13.0248 0.441406H2.36281C2.07706 0.441406 1.95312 0.563169 1.95312 0.8554V28.927C1.95312 29.2157 2.07706 29.341 2.36281 29.341H5.79518C6.08092 29.341 6.20486 29.2157 6.20486 28.927V15.9644H11.5583L17.8481 28.927C18.0099 29.2157 18.1338 29.341 18.4196 29.341H22.179C22.4647 29.341 22.6265 29.2157 22.506 28.927L16.3367 15.6339ZM6.20486 11.8349V4.56743H13.1901C15.8892 4.56743 17.3592 5.88942 17.3592 8.19944C17.3592 10.5095 15.8892 11.8315 13.1901 11.8315H6.20486V11.8349Z" />
    <path d="M34.0261 29.7536C38.7667 29.7536 42.113 27.7324 43.8309 23.1089C43.91 22.8618 43.91 22.7784 43.91 22.6949C43.91 22.5279 43.8309 22.4061 43.6243 22.4061H40.1506C39.8649 22.4061 39.7409 22.4479 39.5791 22.7784C38.5567 24.9666 36.8009 25.8746 34.0226 25.8746C30.5902 25.8746 28.3043 23.7699 27.8533 20.3013H43.9066C44.1923 20.3013 44.3163 20.1761 44.3163 19.8874V19.1046C44.3163 13.2008 40.4777 8.20508 33.9813 8.20508C27.9738 8.20508 23.6016 12.7486 23.6016 18.9794C23.605 25.2136 27.8981 29.7536 34.0261 29.7536ZM33.9847 12.0841C36.8835 12.0841 39.2555 13.6113 39.9475 16.5858H28.0185C28.8345 13.4478 31.4509 12.0841 33.9847 12.0841Z" />
    <path d="M55.6395 16.0466C51.0641 16.0466 47.3047 18.4401 47.3047 23.0219C47.3047 27.3984 50.861 29.7501 54.6996 29.7501C57.8462 29.7501 59.6433 28.8004 61.072 27.3984V28.9256C61.072 29.2144 61.1925 29.3396 61.4817 29.3396H65.5648C65.8505 29.3396 66.0158 29.2144 65.8505 28.9256C65.279 27.8924 65.0759 27.1096 65.0759 25.1301V15.8831C65.0759 11.0926 61.8053 8.20508 56.6998 8.20508C51.88 8.20508 49.0192 10.9291 48.2032 14.2758C48.1619 14.4393 48.1619 14.6063 48.1619 14.6863C48.1619 14.8533 48.2824 14.9333 48.4477 14.9333H51.88C52.1245 14.9333 52.3276 14.8916 52.4515 14.5611C53.147 12.8286 54.5757 11.9206 56.6998 11.9206C59.5573 11.9206 61.072 13.6113 61.072 16.1718V16.9163C59.275 16.3771 57.8462 16.0466 55.6395 16.0466ZM55.2711 25.8746C52.8199 25.8746 51.553 24.7196 51.553 22.8618C51.553 20.7571 53.474 19.7656 55.8426 19.7656C57.9667 19.7656 59.1923 20.0543 61.072 20.6318C61.072 24.0169 58.4177 25.8746 55.2711 25.8746Z" />
    <path d="M77.8739 29.7515C80.5283 29.7515 82.6111 28.927 84.1638 27.3997V28.927C84.1638 29.2157 84.2877 29.3409 84.5734 29.3409H87.7614C88.0471 29.3409 88.1711 29.2157 88.1711 28.927V0.8554C88.1711 0.566648 88.0471 0.441406 87.7614 0.441406H84.5734C84.2877 0.441406 84.1638 0.563169 84.1638 0.8554V10.5964C82.408 9.15267 80.4904 8.19944 77.8739 8.19944C72.3587 8.19944 68.6406 12.4124 68.6406 18.9737C68.6406 25.5419 72.3553 29.7515 77.8739 29.7515ZM78.6899 12.0819C82.1222 12.0819 84.4908 14.9312 84.4908 18.9772C84.4908 23.0232 82.1188 25.8724 78.6899 25.8724C75.1336 25.8724 72.8889 23.0232 72.8889 18.9772C72.8889 14.9312 75.1336 12.0819 78.6899 12.0819Z" />
    <path d="M110.269 8.61328H106.634C106.265 8.61328 106.186 8.73852 106.103 9.02727L101.528 23.3535L96.0542 9.02727C95.9715 8.73852 95.851 8.61328 95.524 8.61328H91.7646C91.5614 8.61328 91.4375 8.73852 91.4375 8.94378C91.4375 9.06902 91.4788 9.14904 91.5201 9.27428L99.6897 29.2121C98.7085 32.8058 97.5655 34.0443 95.524 34.0443C93.8474 34.0443 93.0728 33.1363 92.4187 33.1363C92.2569 33.1363 92.0916 33.2616 92.0916 33.5503V36.6048C92.0916 36.8518 92.1329 37.0571 92.5013 37.2658C93.3998 37.6798 94.4189 37.9268 95.9715 37.9268C98.829 37.9268 101.325 36.5631 103.081 31.3621L110.558 9.19426C110.6 9.0725 110.6 8.98901 110.6 8.90551C110.596 8.69678 110.469 8.61328 110.269 8.61328Z" />
    <path d="M10.7825 66.0858C17.2376 66.0858 21.5685 62.9896 21.5685 57.0858C21.5685 52.504 17.0723 50.4793 12.1699 49.119C8.0869 48.0058 4.98159 46.9308 4.98159 44.1233C4.98159 41.688 7.35016 40.4913 10.5794 40.4913C13.6847 40.4913 16.0946 41.9768 16.3804 44.6625C16.4217 44.9513 16.5043 45.0765 16.79 45.0765H20.219C20.5047 45.0765 20.6287 44.9513 20.6287 44.6625C20.5495 40.3277 16.914 36.3652 10.5794 36.3652C5.34996 36.3652 0.733295 39.0892 0.733295 44.332C0.733295 49.1642 4.49272 51.269 10.2937 52.7128C14.2562 53.7043 17.3202 55.1063 17.3202 57.3363C17.3202 60.516 15.0721 61.9598 10.7825 61.9598C6.8613 61.9598 4.45141 59.9768 4.24829 56.6753C4.24829 56.3865 4.12435 56.2613 3.83861 56.2613H0.409681C0.123937 56.2613 0 56.3865 0 56.6753C0 61.9598 4.69929 66.0858 10.7825 66.0858Z" />
    <path d="M44.9847 56.2213V55.4386C44.9847 49.5348 41.1427 44.5391 34.6497 44.5391C28.6422 44.5391 24.2734 49.0826 24.2734 55.3133C24.2734 61.5476 28.563 66.0876 34.691 66.0876C39.4316 66.0876 42.7814 64.0663 44.4959 59.4428C44.5785 59.1958 44.5785 59.1123 44.5785 59.0288C44.5785 58.8619 44.4959 58.7401 44.2927 58.7401H40.8225C40.5367 58.7401 40.4128 58.7818 40.251 59.1123C39.2285 61.3006 37.4727 62.2086 34.6945 62.2086C31.2621 62.2086 28.9762 60.1038 28.5252 56.6353H44.5785C44.8642 56.6353 44.9847 56.5101 44.9847 56.2213ZM28.6835 52.9198C29.4995 49.7818 32.1159 48.4181 34.6497 48.4181C37.5485 48.4181 39.9205 49.9453 40.6159 52.9198H28.6835Z" />
    <path d="M50.5351 59.5263C50.5351 63.7359 53.1481 66.0911 57.2346 66.0911C58.7046 66.0911 60.3399 65.8441 61.5242 65.3884C61.8513 65.2631 61.8513 65.0579 61.8513 64.6856V61.8781C61.8513 61.6728 61.7308 61.5059 61.5242 61.5059C61.4416 61.5059 61.4003 61.5059 61.2798 61.5476C59.8511 62.0834 58.8286 62.2086 57.603 62.2086C55.4788 62.2086 54.539 61.0118 54.539 58.8236V48.8321H61.0353C61.3211 48.8321 61.445 48.7068 61.445 48.4181V45.3636C61.445 45.0748 61.3245 44.9496 61.0353 44.9496H54.539V39.0041C54.539 38.7153 54.4185 38.5901 54.1327 38.5901H50.9448C50.659 38.5901 50.5351 38.7153 50.5351 39.0041V44.9496H46.9409C46.6552 44.9496 46.5312 45.0748 46.5312 45.3636V48.4181C46.5312 48.7068 46.6552 48.8321 46.9409 48.8321H50.5351V59.5263Z" />
    <path d="M16.3367 88.3117C19.2355 87.3202 21.6075 84.7179 21.6075 80.8806C21.6075 75.8431 18.3404 73.1191 13.0283 73.1191H2.36281C2.07706 73.1191 1.95312 73.2409 1.95312 73.5331V101.605C1.95312 101.893 2.07706 102.019 2.36281 102.019H5.79518C6.08092 102.019 6.20486 101.893 6.20486 101.605V88.6422H11.5583L17.8481 101.605C18.0099 101.893 18.1338 102.019 18.4196 102.019H22.179C22.4647 102.019 22.6265 101.893 22.506 101.605L16.3367 88.3117ZM6.20486 84.5127V77.2486H13.1901C15.8892 77.2486 17.3592 78.5706 17.3592 80.8806C17.3592 83.1907 15.8892 84.5127 13.1901 84.5127H6.20486Z" />
    <path d="M34.3495 80.877C28.2628 80.877 23.6875 85.2535 23.6875 91.6512C23.6875 98.049 28.2628 102.425 34.3495 102.425C40.4362 102.425 45.0116 98.049 45.0116 91.6512C45.0116 85.2535 40.4362 80.877 34.3495 80.877ZM34.3495 98.5465C30.5901 98.5465 27.9358 95.7807 27.9358 91.6512C27.9358 87.5252 30.5901 84.756 34.3495 84.756C38.1055 84.756 40.7633 87.5217 40.7633 91.6512C40.7633 95.7807 38.1055 98.5465 34.3495 98.5465Z" />
    <path d="M57.9666 84.7618C60.2939 84.7618 62.3801 85.8751 63.1961 88.3138C63.3166 88.6443 63.4818 88.6861 63.7676 88.6861H67.0794C67.2412 88.6861 67.3652 88.6026 67.3652 88.4391C67.3652 88.3556 67.3652 88.1921 67.3239 88.0251C66.5458 83.9791 63.1961 80.8828 57.97 80.8828C51.7594 80.8828 47.5938 85.3428 47.5938 91.6571C47.5938 97.9714 51.7629 102.431 57.97 102.431C63.1995 102.431 66.5493 99.3351 67.3239 95.2891C67.3652 95.1256 67.3652 94.9586 67.3652 94.8786C67.3652 94.7116 67.2447 94.6316 67.0794 94.6316H63.7676C63.4818 94.6316 63.3166 94.6733 63.1961 95.0038C62.3801 97.4391 60.2973 98.5558 57.9666 98.5558C54.2485 98.5558 51.8386 96.1206 51.8386 91.6606C51.8386 87.1971 54.2485 84.7618 57.9666 84.7618Z" />
    <path d="M80.7701 88.7639L87.9998 81.9104C88.1237 81.7852 88.165 81.6634 88.165 81.5799C88.165 81.4164 88.0824 81.2912 87.838 81.2912H83.631C83.3453 81.2912 83.18 81.4582 82.9769 81.6634L75.1757 89.0944V73.5331C75.1757 73.2444 75.0552 73.1191 74.7661 73.1191H71.5816C71.2958 73.1191 71.1719 73.2409 71.1719 73.5331V101.605C71.1719 101.893 71.2924 102.019 71.5816 102.019H74.7661C75.0518 102.019 75.1757 101.893 75.1757 101.605V94.0519L77.7474 91.6167L84.3643 101.646C84.5674 101.977 84.7327 102.019 85.0184 102.019H88.6952C88.981 102.019 89.0636 101.893 89.0636 101.688C89.0636 101.605 89.0636 101.566 88.981 101.399L80.7701 88.7639Z" />
    <path d="M99.7982 80.877C93.7907 80.877 89.4219 85.4204 89.4219 91.6512C89.4219 97.8855 93.7115 102.425 99.8395 102.425C104.58 102.425 107.93 100.404 109.644 95.7807C109.727 95.5337 109.727 95.4502 109.727 95.3667C109.727 95.1997 109.644 95.078 109.441 95.078H105.971C105.685 95.078 105.561 95.1197 105.399 95.4502C104.377 97.6385 102.621 98.5465 99.8429 98.5465C96.4106 98.5465 94.1246 96.4417 93.6736 92.9732H109.727C110.013 92.9732 110.137 92.848 110.137 92.5592V91.7765C110.133 85.8727 106.291 80.877 99.7982 80.877ZM93.832 89.2577C94.6479 86.1197 97.2643 84.756 99.7982 84.756C102.697 84.756 105.069 86.2832 105.764 89.2577H93.832Z" />
    <path d="M126.673 97.8455C126.59 97.8455 126.549 97.8455 126.428 97.8872C124.999 98.423 123.977 98.5482 122.751 98.5482C120.627 98.5482 119.687 97.3514 119.687 95.1632V85.1717H126.184C126.47 85.1717 126.593 85.0464 126.593 84.7577V81.7032C126.593 81.4144 126.473 81.2892 126.184 81.2892H119.687V75.3437C119.687 75.0549 119.567 74.9297 119.281 74.9297H116.093C115.807 74.9297 115.684 75.0549 115.684 75.3437V81.2892H112.089C111.804 81.2892 111.68 81.4144 111.68 81.7032V84.7577C111.68 85.0464 111.804 85.1717 112.089 85.1717H115.684V95.8659C115.684 100.075 118.297 102.431 122.383 102.431C123.853 102.431 125.488 102.184 126.673 101.728C127 101.603 127 101.397 127 101.025V98.2177C127 98.0124 126.876 97.8455 126.673 97.8455Z" />
  </svg>
);

const IG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    width="15"
    height="15"
    fill="currentColor"
  >
    <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
  </svg>
);

const IN = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    width="16"
    height="16"
    fill="currentColor"
  >
    <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
  </svg>
);

const BcorpLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 136.94 200.11"
    width="47.77px"
    height="77px"
    fill="currentColor"
  >
    <g id="Logo">
      <g>
        <path
          d="M48.27,66.92h19.98c4.86,0,11.27,0,14.58,2.01c3.63,2.16,6.48,6.09,6.48,11.5c0,5.86-3.08,10.26-8.18,12.34
          v0.16c6.79,1.39,10.49,6.48,10.49,13.27c0,8.1-5.79,15.82-16.74,15.82H48.27V66.92z M55.6,90.6h14.67c8.33,0,11.73-3.01,11.73-8.8
          c0-7.64-5.4-8.72-11.73-8.72H55.6V90.6z M55.6,115.83h18.37c6.32,0,10.34-3.86,10.34-9.87c0-7.18-5.79-9.18-11.96-9.18H55.6
          V115.83z"
        />
        <path
          d="M110.37,94.48c0,23.14-18.76,41.9-41.91,41.9c-23.14,0-41.9-18.76-41.9-41.9c0-23.14,18.76-41.9,41.9-41.9
          C91.61,52.59,110.37,71.35,110.37,94.48z M68.47,46.2c-26.65,0-48.26,21.61-48.26,48.26c0,26.65,21.61,48.26,48.26,48.26
          c26.66,0,48.27-21.61,48.27-48.26C116.74,67.81,95.13,46.2,68.47,46.2z"
        />
      </g>
      <g>
        <rect x="20.3" y="152.91" width="96.33" height="6.33" />
        <path
          d="M113.62,145.94c0.12,0,0.23-0.01,0.34-0.01c0.1-0.01,0.2-0.03,0.28-0.06c0.09-0.04,0.15-0.1,0.2-0.17
          c0.05-0.08,0.07-0.18,0.07-0.31c0-0.11-0.02-0.21-0.06-0.27c-0.04-0.07-0.1-0.12-0.17-0.15c-0.07-0.04-0.14-0.06-0.24-0.08
          c-0.09-0.01-0.17-0.01-0.27-0.01h-0.69v1.07H113.62z M113.85,144.44c0.4,0,0.69,0.08,0.89,0.24c0.19,0.16,0.29,0.4,0.29,0.73
          c0,0.31-0.09,0.52-0.25,0.67c-0.17,0.14-0.38,0.22-0.64,0.25l0.96,1.48h-0.56l-0.92-1.45h-0.55v1.45h-0.53v-3.36H113.85z
           M111.49,147.09c0.11,0.3,0.28,0.57,0.5,0.79c0.21,0.22,0.46,0.39,0.75,0.52c0.28,0.12,0.6,0.18,0.93,0.18
          c0.34,0,0.65-0.06,0.93-0.18c0.29-0.13,0.54-0.3,0.75-0.52c0.21-0.22,0.38-0.48,0.49-0.79c0.12-0.3,0.19-0.62,0.19-0.97
          c0-0.35-0.06-0.67-0.19-0.97c-0.12-0.3-0.29-0.56-0.49-0.78c-0.22-0.22-0.47-0.39-0.75-0.51c-0.28-0.12-0.6-0.19-0.93-0.19
          c-0.33,0-0.65,0.07-0.93,0.19c-0.29,0.13-0.55,0.3-0.75,0.51c-0.22,0.22-0.38,0.48-0.5,0.78c-0.12,0.3-0.18,0.61-0.18,0.97
          C111.3,146.46,111.36,146.79,111.49,147.09 M111.01,144.98c0.15-0.35,0.36-0.64,0.62-0.9c0.26-0.26,0.57-0.45,0.92-0.6
          c0.35-0.15,0.73-0.22,1.11-0.22c0.4,0,0.77,0.07,1.13,0.22c0.34,0.15,0.65,0.34,0.9,0.6c0.26,0.26,0.48,0.56,0.62,0.9
          c0.16,0.35,0.23,0.73,0.23,1.14c0,0.41-0.07,0.79-0.23,1.14c-0.15,0.36-0.36,0.66-0.62,0.92c-0.26,0.27-0.56,0.46-0.9,0.61
          c-0.36,0.14-0.73,0.21-1.13,0.21c-0.39,0-0.76-0.07-1.11-0.21c-0.35-0.15-0.66-0.34-0.92-0.61c-0.26-0.26-0.47-0.56-0.62-0.92
          c-0.16-0.35-0.22-0.73-0.22-1.14C110.78,145.71,110.85,145.33,111.01,144.98"
        />
      </g>
      <g>
        <path
          d="M31.98,24.98c-0.23-0.37-0.51-0.69-0.85-0.96c-0.34-0.28-0.73-0.49-1.16-0.65c-0.43-0.15-0.88-0.23-1.35-0.23
          c-0.86,0-1.59,0.17-2.2,0.5c-0.6,0.33-1.09,0.78-1.46,1.34c-0.37,0.56-0.65,1.2-0.82,1.92c-0.17,0.72-0.26,1.46-0.26,2.22
          c0,0.73,0.08,1.44,0.26,2.13c0.17,0.69,0.44,1.31,0.82,1.87c0.37,0.55,0.86,1,1.46,1.33c0.6,0.33,1.33,0.5,2.2,0.5
          c1.17,0,2.09-0.36,2.74-1.07c0.66-0.72,1.06-1.66,1.21-2.83h3.71c-0.1,1.09-0.35,2.07-0.76,2.95c-0.41,0.88-0.94,1.63-1.61,2.24
          c-0.67,0.62-1.45,1.09-2.34,1.42c-0.89,0.32-1.88,0.49-2.95,0.49c-1.33,0-2.53-0.23-3.6-0.7c-1.07-0.46-1.96-1.1-2.7-1.92
          c-0.73-0.81-1.29-1.77-1.68-2.87c-0.39-1.1-0.59-2.28-0.59-3.55c0-1.3,0.19-2.51,0.59-3.62c0.39-1.11,0.95-2.09,1.68-2.91
          c0.73-0.83,1.63-1.48,2.7-1.95c1.06-0.47,2.26-0.71,3.6-0.71c0.96,0,1.87,0.14,2.72,0.41c0.85,0.28,1.62,0.68,2.29,1.21
          c0.67,0.53,1.23,1.18,1.67,1.96c0.44,0.78,0.72,1.68,0.83,2.68h-3.71C32.35,25.74,32.21,25.34,31.98,24.98z"
        />
        <path
          d="M41.27,34.72c0.52,0.5,1.27,0.76,2.24,0.76c0.7,0,1.3-0.17,1.81-0.52c0.5-0.35,0.81-0.72,0.93-1.11h3.05
          c-0.49,1.51-1.24,2.6-2.25,3.25c-1.01,0.65-2.23,0.98-3.66,0.98c-0.99,0-1.89-0.16-2.68-0.48c-0.8-0.32-1.47-0.77-2.03-1.35
          c-0.55-0.58-0.98-1.28-1.28-2.1c-0.3-0.81-0.45-1.71-0.45-2.68c0-0.94,0.15-1.82,0.46-2.63c0.31-0.81,0.75-1.52,1.32-2.11
          c0.57-0.59,1.25-1.06,2.04-1.4c0.79-0.34,1.66-0.51,2.62-0.51c1.07,0,2.01,0.21,2.81,0.62c0.8,0.42,1.45,0.97,1.96,1.67
          c0.51,0.7,0.88,1.5,1.11,2.39c0.23,0.89,0.31,1.83,0.24,2.81h-9.1C40.46,33.4,40.74,34.22,41.27,34.72z M45.18,28.09
          c-0.42-0.45-1.05-0.68-1.89-0.68c-0.55,0-1.01,0.09-1.38,0.28c-0.37,0.19-0.66,0.42-0.88,0.69c-0.22,0.28-0.37,0.57-0.46,0.88
          c-0.09,0.31-0.14,0.58-0.16,0.83h5.64C45.89,29.21,45.6,28.54,45.18,28.09z"
        />
        <path
          d="M54.37,25.13v2.34h0.05c0.16-0.39,0.38-0.75,0.66-1.09c0.28-0.33,0.59-0.62,0.95-0.85
          c0.36-0.23,0.74-0.42,1.15-0.55c0.41-0.13,0.83-0.19,1.27-0.19c0.23,0,0.48,0.04,0.76,0.12v3.22c-0.16-0.03-0.36-0.06-0.59-0.08
          c-0.23-0.02-0.45-0.04-0.66-0.04c-0.63,0-1.17,0.11-1.61,0.32c-0.44,0.21-0.79,0.5-1.06,0.87c-0.27,0.37-0.46,0.79-0.57,1.28
          c-0.11,0.49-0.17,1.02-0.17,1.59v5.69h-3.46V25.13H54.37z"
        />
        <path
          d="M68.47,25.13v2.32h-2.54v6.25c0,0.59,0.1,0.98,0.29,1.17c0.19,0.19,0.59,0.29,1.17,0.29
          c0.2,0,0.38-0.01,0.56-0.02c0.18-0.02,0.35-0.04,0.51-0.07v2.68c-0.29,0.05-0.62,0.08-0.98,0.1c-0.36,0.02-0.71,0.03-1.05,0.03
          c-0.54,0-1.05-0.04-1.52-0.11c-0.48-0.07-0.9-0.21-1.27-0.43c-0.37-0.21-0.65-0.51-0.87-0.9c-0.21-0.39-0.32-0.9-0.32-1.54v-7.44
          h-2.1v-2.32h2.1v-3.78h3.46v3.78H68.47z"
        />
        <path d="M70.49,23.18v-2.86h3.46v2.86H70.49z M73.95,25.13v12.61h-3.46V25.13H73.95z" />
        <path
          d="M75.33,27.45v-2.32h2.07v-0.98c0-1.12,0.35-2.04,1.05-2.76c0.7-0.72,1.76-1.07,3.17-1.07
          c0.31,0,0.62,0.01,0.93,0.04c0.31,0.02,0.61,0.05,0.9,0.06v2.59c-0.41-0.05-0.83-0.07-1.27-0.07c-0.47,0-0.81,0.11-1.01,0.33
          c-0.2,0.22-0.3,0.59-0.3,1.11v0.76h2.39v2.32h-2.39v10.3H77.4v-10.3H75.33z"
        />
        <path d="M85.6,23.18v-2.86h3.46v2.86H85.6z M89.07,25.13v12.61H85.6V25.13H89.07z" />
        <path
          d="M94.53,34.72c0.52,0.5,1.27,0.76,2.24,0.76c0.7,0,1.3-0.17,1.81-0.52c0.5-0.35,0.81-0.72,0.93-1.11h3.05
          c-0.49,1.51-1.24,2.6-2.24,3.25c-1.01,0.65-2.23,0.98-3.66,0.98c-0.99,0-1.89-0.16-2.68-0.48c-0.8-0.32-1.47-0.77-2.03-1.35
          c-0.55-0.58-0.98-1.28-1.28-2.1c-0.3-0.81-0.45-1.71-0.45-2.68c0-0.94,0.16-1.82,0.46-2.63C90.99,28,91.43,27.3,92,26.71
          c0.57-0.59,1.25-1.06,2.04-1.4c0.79-0.34,1.66-0.51,2.62-0.51c1.07,0,2.01,0.21,2.81,0.62c0.8,0.42,1.45,0.97,1.96,1.67
          c0.51,0.7,0.88,1.5,1.11,2.39c0.23,0.89,0.31,1.83,0.25,2.81h-9.1C93.73,33.4,94.01,34.22,94.53,34.72z M98.45,28.09
          c-0.41-0.45-1.05-0.68-1.89-0.68c-0.55,0-1.01,0.09-1.38,0.28c-0.37,0.19-0.66,0.42-0.88,0.69c-0.22,0.28-0.37,0.57-0.46,0.88
          c-0.09,0.31-0.14,0.58-0.16,0.83h5.63C99.15,29.21,98.86,28.54,98.45,28.09z"
        />
        <path
          d="M113.3,36.14c-0.41,0.68-0.94,1.18-1.6,1.48c-0.66,0.3-1.4,0.45-2.23,0.45c-0.94,0-1.77-0.18-2.49-0.55
          c-0.72-0.37-1.31-0.86-1.77-1.49c-0.46-0.63-0.81-1.35-1.05-2.16c-0.24-0.81-0.35-1.66-0.35-2.54c0-0.85,0.12-1.66,0.35-2.45
          c0.23-0.79,0.59-1.48,1.05-2.09c0.46-0.6,1.05-1.09,1.74-1.45c0.7-0.37,1.51-0.55,2.44-0.55c0.75,0,1.46,0.16,2.13,0.48
          c0.68,0.32,1.21,0.78,1.6,1.4h0.05v-6.34h3.46v17.42h-3.29v-1.61H113.3z M113.15,29.9c-0.1-0.48-0.26-0.9-0.5-1.27
          c-0.24-0.37-0.54-0.66-0.92-0.89c-0.37-0.23-0.85-0.34-1.41-0.34s-1.05,0.11-1.44,0.34c-0.39,0.23-0.7,0.53-0.94,0.9
          c-0.23,0.37-0.4,0.8-0.51,1.28c-0.11,0.48-0.16,0.98-0.16,1.5c0,0.49,0.06,0.98,0.17,1.46c0.11,0.49,0.3,0.92,0.55,1.31
          c0.25,0.38,0.57,0.69,0.95,0.93c0.38,0.24,0.84,0.35,1.38,0.35c0.57,0,1.04-0.11,1.43-0.34c0.38-0.23,0.69-0.53,0.92-0.92
          c0.23-0.38,0.39-0.82,0.49-1.3c0.1-0.49,0.15-0.99,0.15-1.51C113.3,30.88,113.25,30.38,113.15,29.9z"
        />
      </g>
      <g>
        <path
          d="M28.75,171.67c-0.17-1.22-1.34-2.15-2.68-2.15c-2.42,0-3.33,2.06-3.33,4.21c0,2.04,0.91,4.1,3.33,4.1
          c1.65,0,2.58-1.13,2.78-2.75h2.62c-0.28,3.06-2.39,5.02-5.4,5.02c-3.8,0-6.03-2.84-6.03-6.37c0-3.64,2.23-6.48,6.03-6.48
          c2.7,0,4.96,1.58,5.29,4.42H28.75z"
        />
        <path
          d="M37.1,170.69c2.8,0,4.6,1.85,4.6,4.69c0,2.82-1.8,4.67-4.6,4.67c-2.78,0-4.59-1.86-4.59-4.67
          C32.51,172.55,34.32,170.69,37.1,170.69z M37.1,178.22c1.67,0,2.16-1.43,2.16-2.83c0-1.43-0.5-2.85-2.16-2.85
          c-1.65,0-2.15,1.43-2.15,2.85C34.95,176.79,35.45,178.22,37.1,178.22z"
        />
        <path
          d="M43.21,170.92h2.32v1.65h0.04c0.44-1.12,1.65-1.89,2.84-1.89c0.17,0,0.38,0.03,0.53,0.08v2.27
          c-0.23-0.05-0.59-0.08-0.88-0.08c-1.79,0-2.41,1.29-2.41,2.85v4h-2.44V170.92z"
        />
        <path
          d="M49.89,170.93h2.32v1.13h0.03c0.58-0.94,1.54-1.37,2.64-1.37c2.79,0,4.04,2.25,4.04,4.77
          c0,2.37-1.3,4.59-3.9,4.59c-1.06,0-2.08-0.46-2.66-1.34h-0.04v4.23h-2.44V170.93z M56.48,175.4c0-1.41-0.57-2.87-2.13-2.87
          c-1.6,0-2.11,1.43-2.11,2.87c0,1.44,0.55,2.82,2.13,2.82C55.97,178.22,56.48,176.84,56.48,175.4z"
        />
        <path
          d="M64.27,170.69c2.8,0,4.61,1.85,4.61,4.69c0,2.82-1.81,4.67-4.61,4.67c-2.78,0-4.58-1.86-4.58-4.67
          C59.69,172.55,61.49,170.69,64.27,170.69z M64.27,178.22c1.67,0,2.17-1.43,2.17-2.83c0-1.43-0.5-2.85-2.17-2.85
          c-1.65,0-2.15,1.43-2.15,2.85C62.13,176.79,62.63,178.22,64.27,178.22z"
        />
        <path
          d="M70.39,170.92h2.32v1.65h0.04c0.44-1.12,1.65-1.89,2.83-1.89c0.17,0,0.38,0.03,0.53,0.08v2.27
          c-0.22-0.05-0.58-0.08-0.88-0.08c-1.79,0-2.41,1.29-2.41,2.85v4h-2.44V170.92z"
        />
        <path
          d="M76.83,173.66c0.14-2.29,2.18-2.97,4.18-2.97c1.77,0,3.9,0.39,3.9,2.52v4.62c0,0.81,0.08,1.61,0.31,1.98
          h-2.48c-0.08-0.28-0.16-0.57-0.17-0.86c-0.77,0.81-1.9,1.1-2.99,1.1c-1.68,0-3.02-0.84-3.02-2.66c0-2.01,1.51-2.49,3.02-2.7
          c1.5-0.22,2.89-0.17,2.89-1.17c0-1.05-0.73-1.2-1.58-1.2c-0.93,0-1.53,0.38-1.62,1.34H76.83z M82.46,175.47
          c-0.42,0.36-1.27,0.38-2.03,0.52c-0.76,0.15-1.44,0.41-1.44,1.31c0,0.91,0.7,1.13,1.5,1.13c1.9,0,1.98-1.51,1.98-2.04V175.47z"
        />
        <path
          d="M89.83,170.93h1.79v1.63h-1.79v4.4c0,0.83,0.21,1.03,1.03,1.03c0.26,0,0.5-0.02,0.76-0.07v1.91
          c-0.41,0.07-0.95,0.09-1.43,0.09c-1.49,0-2.8-0.35-2.8-2.11v-5.24h-1.48v-1.63h1.48v-2.66h2.44V170.93z"
        />
        <path d="M95.9,169.56h-2.44v-2.01h2.44V169.56z M93.46,170.93h2.44v8.88h-2.44V170.93z" />
        <path
          d="M102.12,170.69c2.8,0,4.61,1.85,4.61,4.69c0,2.82-1.81,4.67-4.61,4.67c-2.78,0-4.58-1.86-4.58-4.67
          C97.54,172.55,99.34,170.69,102.12,170.69z M102.12,178.22c1.67,0,2.17-1.43,2.17-2.83c0-1.43-0.5-2.85-2.17-2.85
          c-1.65,0-2.15,1.43-2.15,2.85C99.97,176.79,100.47,178.22,102.12,178.22z"
        />
        <path
          d="M108.3,170.93h2.32v1.24h0.05c0.62-1,1.68-1.48,2.72-1.48c2.59,0,3.25,1.46,3.25,3.66v5.46h-2.44v-5.02
          c0-1.46-0.43-2.18-1.56-2.18c-1.33,0-1.89,0.74-1.89,2.55v4.66h-2.44V170.93z"
        />
      </g>
    </g>
  </svg>
);
