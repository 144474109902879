import Link from './link';

export const FooterCTA = ({ className }) => {
  return (
    <section className={`footer-cta my-[80px] text-center md:my-[265px] ${className}`}>
      <p className="mb-[30px] text-[27px] leading-[30px] md:mb-[36px] md:text-[30px] md:leading-[28px] lg:text-[46px] lg:leading-[54px]">
        Interested in working <br className="md:hidden lg:block" />
        together?
      </p>
      <Link
        to="/contact"
        className="button w-[175px] px-[16px] pb-[14px] pt-[15px] leading-[21px] tracking-[-0.01em] md:w-[243px] md:pb-[17px] md:pt-[18px] md:text-[18px]"
      >
        Get In Touch
      </Link>
    </section>
  );
};
