import { useLocation } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import { useEffect, useState } from 'react';

import GravityForm from './gravity-form';

const NewsletterForm = () => {
  const { pathname } = useLocation();

  const data = useStaticQuery(graphql`
    query newsletterForm {
      wpGfForm(databaseId: { eq: 3 }) {
        ...GravityFormFields
      }
    }
  `);

  const [submitted, setSubmitted] = useState(false);
  const [success, setSuccess] = useState(false);

  const onSubmit = () => {
    if (data?.wpGfForm?.formFields?.nodes[0]?.value) {
      setSubmitted(true);
    } else {
      setSubmitted(false);
    }
  };

  const onSuccess = () => {
    setSuccess(true);
  };

  // on route change, reset form states
  useEffect(() => {
    setSubmitted(false);
    setSuccess(false);
  }, [pathname]);

  const isSubmitting = submitted && !success;

  return (
    <div className="newsletter relative flex basis-[20%] flex-col">
      {!success && (
        <label htmlFor="newsletter-signup" className="mb-[10px]">
          Sign up for updates
        </label>
      )}
      {isSubmitting && <span className="h-[32px]">Loading</span>}
      <div className={isSubmitting ? 'hidden' : ''}>
        <GravityForm
          key={pathname}
          data={data}
          submitCallback={onSubmit}
          successCallback={onSuccess}
        />
      </div>
    </div>
  );
};

export default NewsletterForm;
