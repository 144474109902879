import clsx from 'clsx';
import { graphql } from 'gatsby';
import { useFormContext } from 'react-hook-form';

import { valueToLowerCase } from '../utils/helpers';
import InputWrapper from './InputWrapper';

const Select = ({ fieldData, name, ...wrapProps }) => {
  const { choices, cssClass, isRequired, size } = fieldData;

  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <InputWrapper
      errors={errors?.[name] || {}}
      inputData={fieldData}
      labelFor={name}
      {...wrapProps}
    >
      <select
        aria-invalid={errors}
        aria-required={isRequired}
        //TODO: GF uses select2 library and classes, need to figure out how to handle here if we're mimicing their functionality
        className={clsx(
          'gravityform__field__input',
          'gravityform__field__input__select',
          'gfield_select',
          cssClass,
          valueToLowerCase(size),
        )}
        id={name}
        name={name}
        {...register(name, {
          required: isRequired && 'This field is required',
        })}
      >
        {choices.map(({ isSelected, text, value }, index) => {
          return (
            <option
              defaultValue={isSelected}
              key={`${name}-${index}`}
              value={value}
            >
              {text}
            </option>
          );
        })}
      </select>
    </InputWrapper>
  );
};

export default Select;

export const SelectField = graphql`
  fragment SelectField on WpSelectField {
    adminLabel
    autocompleteAttribute
    canPrepopulate
    choices {
      isSelected
      text
      value
    }
    conditionalLogic {
      ...ConditionalLogic
    }
    cssClass
    defaultValue
    description
    descriptionPlacement
    errorMessage
    hasAutocomplete
    hasChoiceValue
    hasEnhancedUI
    inputName
    isRequired
    label
    placeholder
    shouldAllowDuplicates
    size
    value
  }
`;
