import clsx from 'clsx';
import { useEffect } from 'react';
import sal from 'sal.js';

import { Footer } from './footer';
import { FooterCTA } from './footerCTA';
import { Header } from './header';

// FX
const observeElsForAnimation = (el) => {
  const { height } = el.getBoundingClientRect();

  // if element is taller than viewport observe children
  if (height > window.innerHeight) {
    el.classList.remove('animate');
    Array.from(el.children).forEach(observeElsForAnimation);
  } else {
    el.classList.add('animate');
    observer.observe(el);
  }
};

const observer =
  typeof window !== 'undefined'
    ? new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            // if in view
            if (entry.intersectionRatio > 0) {
              entry.target.classList.add('animate-in');
            } else {
              entry.target.classList.remove('animate-in');
            }
          });
        },
        { threshold: 0.5 },
      )
    : null;

const Splitting =
  typeof window !== 'undefined' ? require('splitting') : () => null;

const Layout = ({ uri, children }) => {
  const isHome = uri === '/';
  const isContact = uri === '/contact';
  const isCareers = uri === '/careers' ? 'hidden' : '';

  useEffect(() => {
    function playPauseVideo() {
      let videos = document.querySelectorAll('video');

      videos.forEach((video) => {
        // We can only control playback without insteraction if video is mute
        video.controls = false;
        video.playsinline = true;
        video.playsInline = true;
        video.muted = true;
        video.setAttribute('muted', ''); // leave no stones unturned :)
        video.autoplay = true;

        // Let's wait for an event loop tick and be async.
        setTimeout(() => {
          // player.play() might return a promise but it's not guaranteed crossbrowser.
          const promise = video.play();
          // let's play safe to ensure that if we do have a promise
          if (promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                // if promise fails, hide the video and fallback to <img> tag
              });
          }
        }, 0);
      });
    }

    // And you would kick this off where appropriate with:
    playPauseVideo();

    // add animation classes
    const animatedEls = document.querySelectorAll('.animate');

    animatedEls.forEach(observeElsForAnimation);

    sal();

    Splitting({
      /* target: String selector, Element, Array of Elements, or NodeList */
      target: '[data-splitting]',
      /* by: String of the plugin name */
      by: 'lines',
      /* key: Optional String to prefix the CSS variables */
      key: null,
    });
  }, [uri]);

  return (
    <>
      <div className="site overflow-hidden">
        <Header />
        <main className='mx-auto'>
          {children}
        </main>
        {!isContact && <FooterCTA className={isCareers} />}
        <Footer />
      </div>
    </>
  );
};

export default Layout;
