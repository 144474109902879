import clsx from 'clsx';

import { valueToLowerCase } from '../utils/helpers';
import { outputDescription } from '../utils/inputSettings';

const InputWrapper = ({
  children,
  errors,
  inputData: {
    cssClass,
    description,
    descriptionPlacement,
    isRequired,
    label,
    maxLength,
    type,
  },
  labelFor,
  wrapClassName,
  wrapId,
}) => {
  const joinedLabel = `${label}${
    isRequired ? '<span className="gfield_required">*</span>' : ''
  }`;

  return (
    <li
      className={clsx(
        wrapClassName,
        errors?.type && 'gravityform__field--error',
        cssClass,
      )}
      id={wrapId}
    >
      <label
        className="gravityform__label gfield_label"
        htmlFor={labelFor}
        dangerouslySetInnerHTML={{ __html: joinedLabel }}
      />
      {outputDescription(description, descriptionPlacement, 'above', errors)}
      <div
        className={`ginput_container ginput_container_${valueToLowerCase(
          type,
        )}`}
      >
        {children}
        {maxLength > 0 && (
          <div className="charleft ginput_counter warningTextareaInfo">
            {maxLengthSentence(maxLength, type)}
          </div>
        )}
        {/* TODO: Implement number min/max, these currently aren't fetch by the source plugin
                    https://docs.gravityforms.com/field-object/#number
                    <div className="instruction ">
                      Please enter a number from <strong>1</strong> to <strong>15</strong>.
                    </div>
                */}
      </div>
      {outputDescription(description, descriptionPlacement, 'below', errors)}
      {errors && (
        <div
          aria-live="polite"
          className="gravityform__error_message gfield_description validation_message"
        >
          {errors.message}
        </div>
      )}
    </li>
  );
};

const maxLengthSentence = (length, type) => {
  let word = type === 'number' ? 'numbers' : 'characters';
  return length && ` (maxiumum ${length} ${word})`;
};

export default InputWrapper;
