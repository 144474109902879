/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import 'splitting/dist/splitting.css';
import 'splitting/dist/splitting-cells.css';
import './node_modules/sal.js/dist/sal.css';
import 'swiper/css';
import 'swiper/css/free-mode';
import './src/styles/app.css';

// export const onInitialClientRender = () => {

//   // trigger DOMContentLoaded later for chatbot script
//   const handler = () => {
//     window.document.dispatchEvent(new Event("DOMContentLoaded"));
//   }

//   if(typeof window !== 'undefined') {
//     console.log('undefined')
//     if (document.readyState === "complete") {
//       console.log('complete')
//       window.document.dispatchEvent(new Event("DOMContentLoaded"));
//     } else {
//       window.addEventListener('load', handler);
//       console.log('other')
//       return () => document.removeEventListener('load', handler);
//     }
//   }

// }

function externalLinks() {
  for(var c = document.getElementsByTagName("a"), a = 0;a < c.length;a++) {
    var b = c[a];
    b.getAttribute("href") && b.hostname !== location.hostname && (b.target = "_blank")
  }
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  // console.log(location);

  externalLinks();

  const hasAlert = document.getElementById('hello-bar');

  const pathArr = location.pathname.split('/');

  // add body class based on url
  const prevClass =
    prevLocation?.pathname
      .split('/')
      .filter((n) => n)
      .join('-') || 'home';
  const bodyClass = pathArr.filter((n) => n).join('-') || 'home';

  document.body.classList.remove(prevClass);
  document.body.classList.add(bodyClass);
  if( hasAlert ) {
    document.body.classList.add('hello');
  }

};